<template>
  <div>
    <el-main>
      <search-project-type @submit="submitSearch"/>
      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="showNewModal"
        >
          新增
        </el-button>
      </div>
      <el-table :data="data" row-key="id" :show-header="false" v-loading="loading">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 49px; height: 49px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-descriptions :column="4" :title="scope.row.name">
              <template #extra>
                <el-tooltip content="编辑">
                  <el-icon :size="20" style="margin-right:10px" @click="editProductType(scope.row.id)">
                    <Edit  color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-descriptions-item label="项目数量：">{{ scope.row.projectCount }}</el-descriptions-item>
              <el-descriptions-item label="节点模板数量：">{{ scope.row.nodeTemplateCount }}</el-descriptions-item>
              <el-descriptions-item label="调研模板数量：">{{ scope.row.surveyTemplateCount }}</el-descriptions-item>
              <el-descriptions-item label="合同模板数量：">{{ scope.row.contractTemplateCount }}</el-descriptions-item>
            </el-descriptions>

          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="fetchData"
      />
    </el-main>
    <new-project-type v-model:visible="isShowNewModal" v-if="isShowNewModal" @completed="fetchData"></new-project-type>
    <edit-project-type :id="editingId" v-model:visible="isShowEditModal" v-if="isShowEditModal"
                       @completed="fetchData"></edit-project-type>
  </div>


</template>

<script>
import SearchProjectType from "@/view/project_type/Search.vue";
import NewProjectType from "@/view/project_type/New.vue";
import EditProjectType from "@/view/project_type/Edit.vue";
import {delProjectType, listProjectType} from "@/api/project_type";
import {isResOK} from "@/api/response";
import pagination from '@/components/Pagination/index.vue'
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

export default {
  name: "index",
  components: {EditProjectType, NewProjectType, SearchProjectType, pagination},
  data() {
    return {
      // 总条数
      total: 0,
      loading: true,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      isShowNewModal: false,
      isShowEditModal: false,
      data: [],
      imgUrl: require(`/src/assets/project_type.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      editingId: 0, // 正在编辑的记录id
    }
  },
  computed: {},
  created() {
    this.fetchData();
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },
    submitSearch(search) {
      this.query = Object.assign({},
          { pageNum: this.query.pageNum },
          search)
      this.fetchData()
    },
    showNewModal() {
      this.isShowNewModal = true
    },
    editProductType(id) {
      this.editingId = id
      this.isShowEditModal = true
    },
    fetchData() {
      this.loading = true
      listProjectType(Object.assign({}, this.query, this.sort)).then((res) => {
        if (isResOK(res)) {
          this.data = res.rows
          this.total = res.total
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleDelete(row) {
      const id = row.id
      this.$messageBox.confirm(
          '确定删除吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delProjectType(id, { operatingMode: 'web' }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '数据删除成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {})
    },
  }
}
</script>

<style scoped lang="less">
</style>
