<template>
  <div>
    <el-form ref="searchProjectTypeFormRef" :inline="true" :model="formState" label-width="85px" label-position="left">
      <el-row>
        <el-col
            :span="6"
        >
          <el-form-item label="类型名称" prop="name">
            <el-input style="width: 240px" v-model="formState.name" placeholder="请输入类型名称" clearable/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button type="warning" @click="handleReset">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SearchProjectType",
  emits: ['submit'],
  data() {
    return {
      formState: {
        name: ''
      }
    }
  },
  methods: {
    handleSearch() {
      this.$emit('submit', this.formState)
    },

    handleReset() {
      this.$refs.searchProjectTypeFormRef.resetFields()
      this.$emit('submit', this.formState)
    },
  }
}
</script>

<style scoped>

</style>
