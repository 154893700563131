<template>
  <el-dialog
      v-model="visible"
      title="编辑项目类型"
      width="30%"
      :before-close="handleClose"
  >
    <el-form>
      <el-form ref="formRef" :inline="false" :model="formState"  :rules="rules">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="formState.name" maxlength="10" placeholder="请输入类型名称" clearable />
        </el-form-item>
      </el-form>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit" :disabled="submitting">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {updateProjectType,getProjectType} from "@/api/project_type";
import {isResOK} from "@/api/response";

export default {
  name: 'Edit<%= modelName %>',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:visible', 'completed'],
  data(){
    return {
      loading: true,
      submitting: false,
      formState: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入项目类型', trigger: 'blur' },
          { min: 1, max: 10, message: '项目类型长度小于10', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      getProjectType(this.id).then(res => {
        if (isResOK(res)){
          this.formState = res.data;
        }
      })
    },
    handleSubmit() {
      if (this.submitting) {
        //  判断重复提交
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }
      this.submitting = true
      this.$refs.formRef.validate((valid, fields) => {
        if (valid) {
          updateProjectType(Object.assign({}, this.formState, Object.assign({}, this.formState, { operatingMode: 'web' }))).then(res => {
            if (isResOK(res)){
              this.$message({
                message: '数据修改成功',
                type: 'success',
              })
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
          }).finally(() => {
            this.submitting = false
          })
        }else{
          this.submitting = false
        }
      }).catch(() => {
        this.submitting = false
      })
    },
    handleClose(){
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
